import { render, staticRenderFns } from "./DriverComparison.vue?vue&type=template&id=5588b547&scoped=true"
import script from "./DriverComparison.vue?vue&type=script&lang=js"
export * from "./DriverComparison.vue?vue&type=script&lang=js"
import style0 from "./DriverComparison.vue?vue&type=style&index=0&id=5588b547&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5588b547",
  null
  
)

export default component.exports